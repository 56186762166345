import { render, staticRenderFns } from "./MainBoard.vue?vue&type=template&id=61cd198e&scoped=true&"
import script from "./MainBoard.vue?vue&type=script&lang=js&"
export * from "./MainBoard.vue?vue&type=script&lang=js&"
import style0 from "./MainBoard.vue?vue&type=style&index=0&id=61cd198e&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61cd198e",
  null
  
)

export default component.exports